.button {
  border: none;
  padding: 10px;
  display: inline-block;
  border-radius: 6px;
  background-color: black;
  color: white;
}

.button:hover {
  cursor: pointer;
}

.pill {
  border-radius: 9999px !important;
}
