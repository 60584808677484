:root {
  --primary: #2C735E;
  --error: #A32616;
  --vipps: #ff5b24;
}

@font-face {
  font-family: 'Sen';
  src: local('Sen'), url(./fonts/Sen-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Sen';
  font-weight: bold;
  src: local('Sen'), url(./fonts/Sen-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Sen';
  font-weight: bold;
  src: local('Sen'), url(./fonts/Sen-ExtraBold.otf) format('opentype');
}

body {
  margin: 0;
  font-family: "Sen", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loading-cover {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bold {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.row {
  flex-direction: row !important;
}

.m-0 {
  margin: 0;
}
