.container {
    position: relative;
    display: inline-block;
    height: 100%;
    margin-right: 10px;
}

.selectedItem {
    height: 100%;
    padding: 12px !important;
    font-size: 16px;
    border: 1px solid #B3B3B3;
    border-radius: 6px;
}

.dropDown {
    position: absolute;
    margin-top: 6px;
    background-color: white;
    border: 1px solid #B3B3B3;
    border-radius: 6px;
    height: 35vh;
    overflow: auto;
}

.dropDownItem {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 6px;
    font-size: 16px;
    padding-right: 24px;

}

.dropDownItem:hover {
    cursor: pointer;
    background-color: lightgray;
}

.dropDownItem img {
    width: 16px;
    height: 16px;
    border-radius: 9999px;
    margin-right: 6px;
}

.dropDownItem .callingCode {
    margin-right: 12px;
    white-space: nowrap;
}

.borderBottom {
    border-bottom: 1px solid lightgray;
}