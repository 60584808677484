.login {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  max-width: 200px;
  margin-bottom: 32px;
}

.optionDivider {
  margin: 32px 0;
}

.login button {
  width: 100%;
  max-width: 400px;
}

.vippsButton {
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.smsInfo {

}

.smsInfo p {
  margin: 0 0 16px 0;
}

.link {
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
}

.smsButton {
  font-size: 14px;
  margin-bottom: 20px;
}

.phoneNumberInputWrapper {
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
}

.phoneNumberInputWrapper p {
  margin: 0 10px 0 0;
  padding-bottom: 4px;
  color: lightgray;
  font-weight: 600;
  font-size: 20px;
}

.phoneNumberInputLabel {
  margin: 0 0 24px 0;
  font-size: 16px;
  font-weight: 600;
}

.phoneNumberInput {
  padding: 12px;
  width: 100%;
  font-size: 16px;
  border: 1px solid #B3B3B3;
  border-radius: 6px;
}

.phoneNumberInput:focus {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
.phoneNumberInput::-webkit-outer-spin-button,
.phoneNumberInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.phoneNumberInput[type="number"] {
  -moz-appearance: textfield;
}

.inputErrorMessageWrapper {
  align-self: flex-start;
  margin: 0 0 24px 0;
}

.inputErrorMessageWrapper p {
  font-size: 12px;
  color: var(--error);
  margin: 0;
}

.resendSMSWrapper {
  width: 100%;
}

.resendSMSWrapper p {
  margin: 0 0 10px 0;
}

.progressBar {
  height: 16px;
  width: 100%;
  background-color: lightgray;
  border-radius: 9999px;
  overflow: hidden;
}

.progress {
  transition: width 1s linear;
  width: 0px;
  height: 100%;
  background-color: #6cd1b2;
}

.resendSMSButton {
  background-color: white !important;
  color: black !important;
  border: 1px solid black !important;
  font-weight: bold;
  margin-bottom: 24px;
}
